import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import store from './redux/store';
import UpgradeMessage from './UpgradeMessage';
import "@wix/design-system/styles.global.css";

interface AppProps extends WithTranslation { }

class App extends React.Component<AppProps> {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="*" element={<UpgradeMessage />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default withTranslation()(App);
