import React from 'react';
import { Card, Text, Box, Layout, Heading, Cell } from '@wix/design-system';

const UpgradeMessage = () => {
  return (
  <Box height="100vh" display="flex" verticalAlign="middle" align="center">
  <Card>
  <Box width="800px" padding="40px">
  <Layout>
  <Cell span={12}>
  <Heading appearance="H1">
  We're moving to a new system!
  </Heading>
  </Cell>

  <Cell span={12}>
  <Box marginTop={4}>
  <Text size="medium">
  The previous Seats system is no longer in use.
  <br/>
  To submit a new seat request or view your current seats, please follow the link below to access our new platform.
  </Text>
  </Box>
  </Cell>

  <Cell span={12}>
  <Box marginTop={3}>
  <a href="https://eedf.fa.em2.oraclecloud.com" target="_blank">
  Seats in Oracle
  </a>
  </Box>
  </Cell>
  </Layout>
  </Box>
  </Card>
  </Box>
  );
  };


export default UpgradeMessage;
